<template>
  <div v-if="event">
    <TitleBold center class="mb-2">{{
      $t("event.request.create.title")
    }}</TitleBold>
    <div v-if="event.participation_type.length > 1" class="mb-4">
      <radio-buttons-input
        :items="
          eventArticleTypes.filter(val =>
            event.participation_type.includes(val.value)
          )
        "
        v-model="participationTypeNow"
        button
        button-group
        inline
      ></radio-buttons-input>
    </div>
    <validation-observer ref="observer">
      <div class="row gy-2 mb-4">
        <div
          v-for="(element, key) in fields
            .filter(val => !val.hidden && !val.deleted)
            .map(val => ({ ...val, isShown: isShown(val) }))"
          :key="key"
          class="col col-12"
          :class="{
            [`col-md-${element.col}`]: element.col,
            'd-none': !element.isShown
          }"
        >
          <RegInput
            v-bind="element"
            :value="fieldValues[element.id] || ''"
            @input="
              e => (fieldValues = { ...(fieldValues || {}), [element.id]: e })
            "
            :validate-disabled="!element.isShown"
          ></RegInput>
        </div>
      </div>
      <div>
        <ul class="list-unstyled">
          <li>
            <span class="text-danger">*</span> -
            {{ $t("event.fields.required") }}
          </li>
        </ul>
      </div>
      <TitleBold center v-if="event.sections && event.sections.length"
        >{{ $t("event.section.edit.choose2") }}
      </TitleBold>
      <MiddleContainer
        class="mb-3"
        v-if="event.sections && event.sections.length"
      >
        <SectionList v-model="sectionSelected" :section-list="event.sections" />
      </MiddleContainer>
      <CheckBoxInput
        required
        :label="$t('markdownPage.personalData')"
        v-model="agreePersonalData"
        :validate-disabled="false"
      >
        <template v-slot:help>
          {{ $t("event.fields.urlAccept[0]") }}
          <router-link
            :to="{ name: 'PersonalData' }"
            target="_blank"
            class="color-inherit"
            >{{ $t("event.fields.urlAccept[1]") }}</router-link
          ></template
        >
      </CheckBoxInput>
      <CheckBoxInput
        required
        :label="$t('markdownPage.privatePolicy')"
        v-model="agreePolicy"
        :validate-disabled="false"
      >
        <template v-slot:help>
          {{ $t("event.fields.urlAccept[0]") }}
          <router-link
            :to="{ name: 'Policy' }"
            target="_blank"
            class="color-inherit"
            >{{ $t("event.fields.urlAccept[1]") }}</router-link
          ></template
        >
      </CheckBoxInput>
    </validation-observer>
    <div class="d-flex justify-content-center mb-5">
      <div class="btn-group">
        <FilledButton color="primary" class="btn-wide" @click.native="onSubmit"
          >{{ $t("event.actions.send") }}
        </FilledButton>
      </div>
    </div>
  </div>
  <div v-else></div>
</template>

<script>
import TitleBold from "../components/base/TitleBold";
import FilledButton from "../components/buttons/FilledButton";
import MiddleContainer from "../components/base/MiddleContainer";
import SectionList from "../components/events/SectionList";
import RegInput from "../components/inputs/RegInput";
import { ValidationObserver } from "vee-validate";
import API from "../common/api/api.functions";
import { mapActions, mapGetters } from "vuex";
import RadioButtonsInput from "../components/inputs/RadioButtonsInput";
import loadingMixin from "../mixins/loadingMixin";
import CheckBoxInput from "../components/inputs/CheckBoxInput";
import valueToFieldMixin from "../mixins/valueToFieldMixin";

export default {
  name: "NewMember",
  components: {
    CheckBoxInput,
    RadioButtonsInput,
    RegInput,
    SectionList,
    MiddleContainer,
    FilledButton,
    TitleBold,
    ValidationObserver
  },
  data: () => ({
    fields: [],
    documents: [],
    fieldValues: {},
    participationTypeNow: null,
    agreePersonalData: false,
    agreePolicy: false,
    event: null
  }),
  mixins: [loadingMixin, valueToFieldMixin],
  computed: {
    ...mapGetters(["auth", "user", "userIsAdmin", "eventArticleTypes"]),
    sectionSelected: {
      get() {
        const section = this.$route.query?.section;
        return section ? parseInt(section) : null;
      },
      async set(val) {
        const routerObj = {};
        const query = { ...(this.$route.query || {}) };
        if (val) query.section = `${val}`;
        else if (query.section) delete query.section;
        routerObj.query = query;
        await this.$router.replace(routerObj);
      }
    },
    eventSlug() {
      return this.$route.params.slug || null;
    }
  },
  methods: {
    ...mapActions(["fieldToFront", "fieldToBack"]),
    isShown(element) {
      if (!element.eventTypeRequired) return true;
      if (element.participationTypes?.length) {
        let participationTypesSet = new Set([...element.participationTypes]);
        let intersection = [...(this.participationTypeNow || [])].filter(x =>
          participationTypesSet.has(x)
        );
        return !!intersection.length;
      }
      if (element.eventTypes?.length) {
        let eventTypesSet = new Set([...element.eventTypes]);
        let intersection = [...([this.event.event_type] || [])].filter(x =>
          eventTypesSet.has(x)
        );
        return !!intersection.length;
      }
      return true;
    },
    async onSubmit() {
      for (const nowRef of Object.values(this.$refs.observer.refs)) {
        // console.log(nowRef.name);
        if (nowRef.disabled) continue;
        // console.log(nowRef.name);
        nowRef.setFlags({ dirty: true });
      }
      let validation = await this.$refs.observer.validate();
      if (validation) {
        if (this.event.sections.length && !this.sectionSelected) {
          this.$toast.error("Выберите секцию");
          return;
        }
        const sendData = new FormData();
        sendData.append("event", this.eventSlug);
        if (this.participationTypeNow)
          sendData.append("participation_type", this.participationTypeNow);
        if (this.sectionSelected)
          sendData.append("section", this.sectionSelected);

        for (const fieldNow of Object.entries(this.fieldValues)) {
          let field = this.fields.find(val => val.id === parseInt(fieldNow[0]));
          if (Array.isArray(fieldNow[1])) {
            for (const fieldNode of fieldNow[1]) {
              sendData.append(
                `field_${fieldNow[0]}`,
                this.valueFormatToBack(field, fieldNode)
              );
            }
            continue;
          }
          sendData.append(
            `field_${fieldNow[0]}`,
            this.valueFormatToBack(field, fieldNow[1])
          );
        }

        let newMemberResponse = await API.eventRequestCreate(sendData)
          .then(res => res.data)
          .catch(error => {
            let errorData = error?.response?.data || {};
            let userError = errorData.user || [];
            if (
              userError.length &&
              userError[0] === "user_with_same_data_already_exist"
            ) {
              this.$toast.error(
                "Пользователь с указанными данными уже существует, пройдите авторизацию"
              );
              this.$store.commit("UPDATE_SIGNIN_MODAL", true);
              return;
            }
            this.$toast.error(JSON.stringify(error?.response?.data || {}));
            // this.notFound = true;
            // this.loading--;
          });
        if (!newMemberResponse) return;
        if (newMemberResponse.request_id) {
          if (newMemberResponse.token && newMemberResponse.account) {
            await this.$store.dispatch("obtainToken", {
              token: newMemberResponse.token,
              account: newMemberResponse.account
            });
          }
          await this.$router.push({
            name: "NoteDetails",
            params: { id: newMemberResponse.request_id }
          });
          this.$toast.success(
            `Ваш номер заявки: ${newMemberResponse.request_id}`
          );
          return;
        }
        this.$toast.success(JSON.stringify(newMemberResponse), {
          timeout: false
        });
        // if (withUserError) {
        //   this.$toast.error(
        //     "Пользователь с указанными данными уже существует, пройдите авторизацию"
        //   );
        //   this.$store.commit("UPDATE_SIGNIN_MODAL", true);
        // } else {
        //   this.$toast.success("Успешная форма!");
        // }
      } else {
        this.$toast.error("Исправьте ошибки!");
        let refs = this.$refs.observer.refs;
        for (const ref of Object.values(refs)) {
          if (ref.errors.length) {
            ref.$el.scrollIntoView({ block: "center", behavior: "smooth" });
            break;
          }
        }
      }
    },
    sectionCheck(val) {
      if (val && !(this.event?.sections || []).find(v => v.id === val))
        this.notFound = true;
    }
  },
  watch: {
    sectionSelected(val) {
      this.sectionCheck(val);
    },
    user(val) {
      this.loading++;
      for (const field of this.fields.filter(value => value.userField)) {
        let newVal = val[field.userField] || null;
        if (field.type === "radio_buttons" && newVal) newVal = [newVal];
        if (newVal) this.fieldValues[field.id] = newVal;
        field.disabled = !!newVal;
      }
      this.$nextTick(() => this.loading--);
    }
  },
  async mounted() {
    this.loading++;
    const getFunc = this.userIsAdmin
      ? API.getEventAdminDetails
      : API.getEventDetails;
    let eventResponse = await getFunc(this.eventSlug)
      .then(res => res.data)
      .catch(error => {
        this.$toast.error(JSON.stringify(error?.response?.data || {}));
        this.loading--;
        this.notFound = true;
      });
    if (eventResponse && eventResponse.slug) {
      if (!eventResponse.allowed_request) {
        this.notFound = true;
        this.loading--;
        return;
      }
      if (eventResponse.application_link) {
        window.location.href = eventResponse.application_link;
      }
      this.event = eventResponse;
      this.sectionCheck(this.sectionSelected);
      this.$store.commit("UPDATE_HEADER_TITLE", this.event.title || null);
      this.participationTypeNow = [this.event.participation_type[0]];

      let modifiedFields = [];
      for (const field of this.event.fields) {
        const formattedField = await this.fieldToFront({ field });
        if (formattedField.userField && this.auth) {
          let newVal = this.user[formattedField.userField] || null;
          if (formattedField.type === "radio_buttons" && newVal) {
            newVal = [newVal];
          }
          this.fieldValues[formattedField.id] = newVal;
          formattedField.disabled = !!newVal;
        }
        modifiedFields = [...modifiedFields, formattedField];
      }
      this.fields = modifiedFields.filter(val => !val.document);
      this.documents = modifiedFields.filter(val => !!val.document);
      this.$store.commit("UPDATE_HEADER_IMAGE", eventResponse.big_image);
      this.$nextTick(() => this.loading--);
    }
  }
};
</script>
